.jq-checkbox {
	top: -1px;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	margin: 0 4px 0 0;
	border: 1px solid #C3C3C3;
	border-radius: 3px;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: 0 1px 1px rgba(0,0,0,.05), inset -1px -1px #FFF, inset 1px -1px #FFF;
	cursor: pointer;
}
.jq-checkbox.checked .jq-checkbox__div {
	width: 12px;
	height: 12px;
	margin: 2px 0 0 2px;
	border-radius: 2px;
	background: #666;
	box-shadow: inset 0 -3px 6px #AAA;
}
.jq-checkbox.focused {
	border: 1px solid #08C;
}
.jq-checkbox.disabled {
	opacity: .55;
}


.jq-radio {
	top: -1px;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	margin: 0 4px 0 0;
	border: 1px solid #C3C3C3;
	border-radius: 50%;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: 0 1px 1px rgba(0,0,0,.05), inset -1px -1px #FFF, inset 1px -1px #FFF;
	cursor: pointer;
}
.jq-radio.checked .jq-radio__div {
	width: 10px;
	height: 10px;
	margin: 3px 0 0 3px;
	border-radius: 50%;
	background: #777;
	box-shadow: inset 1px 1px 1px rgba(0,0,0,.7);
}
.jq-radio.focused {
	border: 1px solid #08C;
}
.jq-radio.disabled {
	opacity: .55;
}


.jq-file {
	width: 270px;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
}
.jq-file input {
	height: auto;
	line-height: 1em;
	cursor: pointer;
}
.jq-file__name {
	overflow: hidden;
	box-sizing: border-box;
	width: 100%;
	height: 34px;
	padding: 0 80px 0 10px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1;
	font: 14px/32px Arial, sans-serif;
	color: #333;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.jq-file__browse {
	position: absolute;
	top: 1px;
	right: 1px;
	padding: 0 10px;
	border-left: 1px solid #CCC;
	border-radius: 0 4px 4px 0;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/32px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
}
.jq-file:hover .jq-file__browse {
	background: #E6E6E6;
	background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
	background:         linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-file:active .jq-file__browse {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-file.focused .jq-file__name {
	border: 1px solid #5794BF;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}


.jq-number {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: 0 36px 0 0;
}
.jq-number__field {
	width: 100px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
}
.jq-number__field:hover {
	border-color: #B3B3B3;
}
.jq-number__field input {
	box-sizing: border-box;
	width: 100%;
	padding: 8px 9px;
	border: none;
	outline: none;
	background: none;
	font: 14px Arial, sans-serif;
	color: #333;
	text-align: left; /* для Opera Presto */
	-moz-appearance: textfield;
}
.jq-number__field input::-webkit-inner-spin-button,
.jq-number__field input::-webkit-outer-spin-button {
	margin: 0; /* в каких-то браузерах присутствует отступ */
	-webkit-appearance: none;
}
.jq-number__spin {
	position: absolute;
	top: 0;
	right: 0;
	width: 32px;
	height: 14px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	text-shadow: 1px 1px #FFF;
	cursor: pointer;
}
.jq-number__spin.minus {
	top: auto;
	bottom: 0;
}
.jq-number__spin:hover {
	background: #E6E6E6;
	background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
	background:         linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-number__spin:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-number__spin:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 11px;
	width: 0;
	height: 0;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #999;
	border-left: 5px solid transparent;
}
.jq-number__spin.minus:after {
	top: 5px;
	border-top: 5px solid #999;
	border-right: 5px solid transparent;
	border-bottom: none;
	border-left: 5px solid transparent;
}
.jq-number__spin.minus:hover:after {
	border-top-color: #000;
}
.jq-number__spin.plus:hover:after {
	border-bottom-color: #000;
}
.jq-number.focused .jq-number__field {
	border: 1px solid #5794BF;
}
.jq-number.disabled .jq-number__field,
.jq-number.disabled .jq-number__spin {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-number.disabled .jq-number__spin:after {
	border-bottom-color: #AAA;
}
.jq-number.disabled .jq-number__spin.minus:after {
	border-top-color: #AAA;
}


.jq-selectbox {
	vertical-align: middle;
	cursor: pointer;
}
.jq-selectbox__select {
	height: 32px;
	padding: 0 45px 0 10px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/32px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
}
.jq-selectbox__select:hover {
	background: #E6E6E6;
	background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
	background:         linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-selectbox__select:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-selectbox.focused .jq-selectbox__select {
	border: 1px solid #5794BF;
}
.jq-selectbox.disabled .jq-selectbox__select {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-selectbox__select-text {
	display: block;
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.jq-selectbox .placeholder {
	color: #888;
}
.jq-selectbox__trigger {
	position: absolute;
	top: 0;
	right: 0;
	width: 34px;
	height: 100%;
	border-left: 1px solid #CCC;
}
.jq-selectbox__trigger-arrow {
	position: absolute;
	top: 14px;
	right: 12px;
	width: 0;
	height: 0;
	border-top: 5px solid #999;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow {
	border-top-color: #000;
}
.jq-selectbox.disabled .jq-selectbox__trigger-arrow {
	border-top-color: #AAA;
}
.jq-selectbox__dropdown {
	box-sizing: border-box;
	width: 100%;
	margin: 2px 0 0;
	padding: 0;
	border: 1px solid #CCC;
	border-radius: 4px;
	background: #FFF;
	box-shadow: 0 2px 10px rgba(0,0,0,.2);
	font: 14px/18px Arial, sans-serif;
}
.jq-selectbox__search {
	margin: 5px;
}
.jq-selectbox__search input {
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 5px 27px 6px 8px;
	border: 1px solid #CCC;
	border-radius: 3px;
	outline: none;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==) no-repeat 100% 50%;
	box-shadow: inset 1px 1px #F1F1F1;
	color: #333;
	-webkit-appearance: textfield;
}
.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
	-webkit-appearance: none;
}
.jq-selectbox__not-found {
	margin: 5px;
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	padding: 0;
}
.jq-selectbox li {
	min-height: 18px;
	padding: 5px 10px 6px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	white-space: nowrap;
	color: #231F20;
}
.jq-selectbox li.selected {
	background-color: #A3ABB1;
	color: #FFF;
}
.jq-selectbox li:hover {
	background-color: #08C;
	color: #FFF;
}
.jq-selectbox li.disabled {
	color: #AAA;
}
.jq-selectbox li.disabled:hover {
	background: none;
}
.jq-selectbox li.optgroup {
	font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}


.jq-select-multiple {
	box-sizing: border-box;
	padding: 1px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/18px Arial, sans-serif;
	color: #333;
	cursor: default;
}
.jq-select-multiple.focused {
	border: 1px solid #5794BF;
}
.jq-select-multiple.disabled {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-select-multiple ul {
	margin: 0;
	padding: 0;
}
.jq-select-multiple li {
	padding: 3px 9px 4px;
	list-style: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	white-space: nowrap;
}
.jq-select-multiple li:first-child {
	border-radius: 3px 3px 0 0;
}
.jq-select-multiple li:last-child {
	border-radius: 0 0 3px 3px;
}
.jq-select-multiple li.selected {
	background: #08C;
	color: #FFF;
}
.jq-select-multiple li.disabled {
	color: #AAA;
}
.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
	background: #CCC;
	color: #FFF;
}
.jq-select-multiple li.optgroup {
	font-weight: bold;
}
.jq-select-multiple li.option {
	padding-left: 25px;
}


input[type='email'].styler,
input[type='password'].styler,
input[type='search'].styler,
input[type='tel'].styler,
input[type='text'].styler,
input[type='url'].styler,
textarea.styler {
	padding: 8px 9px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px Arial, sans-serif;
	color: #333;
}
input[type='search'].styler {
	-webkit-appearance: none;
}
textarea.styler {
	overflow: auto;
}
input[type='email'].styler:hover,
input[type='password'].styler:hover,
input[type='search'].styler:hover,
input[type='tel'].styler:hover,
input[type='text'].styler:hover,
input[type='url'].styler:hover,
textarea.styler:hover {
	border-color: #B3B3B3;
}
input[type='email'].styler:focus,
input[type='password'].styler:focus,
input[type='search'].styler:focus,
input[type='tel'].styler:focus,
input[type='text'].styler:focus,
input[type='url'].styler:focus,
textarea.styler:focus {
	border-color: #CCC;
	border-top-color: #B3B3B3;
	outline: none;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
button.styler,
input[type='button'].styler,
input[type='submit'].styler,
input[type='reset'].styler {
	overflow: visible;
	padding: 8px 11px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	outline: none;
	background: #F5F5F5;
	background: -webkit-linear-gradient(#FFF, #E6E6E6);
	background:         linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
	cursor: pointer;
}
button.styler::-moz-focus-inner,
input[type='button'].styler::-moz-focus-inner,
input[type='submit'].styler::-moz-focus-inner,
input[type='reset'].styler::-moz-focus-inner {
	padding: 0;
	border: 0;
}
button.styler:not([disabled]):hover,
input[type='button'].styler:not([disabled]):hover,
input[type='submit'].styler:not([disabled]):hover,
input[type='reset'].styler:hover {
	background: #E6E6E6;
	background: -webkit-linear-gradient(#F6F6F6, #E6E6E6);
	background:         linear-gradient(#F6F6F6, #E6E6E6);
}
button.styler:not([disabled]):active,
input[type='button'].styler:not([disabled]):active,
input[type='submit'].styler:not([disabled]):active,
input[type='reset'].styler:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
button.styler[disabled],
input[type='button'].styler[disabled],
input[type='submit'].styler[disabled] {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}